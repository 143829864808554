import * as React from "react"
//import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className={styles.textCenter}>
      <StaticImage
        src="../images/soyas-icon.png"
        loading="eager"
        width={256}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt=""
        style={{ marginBottom: `var(--space-5)`, borderRadius: "50%", objectFit: "cover" }}
      />
      <div className={styles.authorName}>
        Soyasu Kitano / 北乃そやす
      </div>
      <div
        class={styles.socialList}
      >
        <a
          href="https://twitter.com/soyas_k"
        //class={styles.socialItemLink}
        >
          <StaticImage
            src="../images/twitter-logo.svg"
            loading="eager"
            height={32}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
          //style={{ marginBottom: `var(--space-3)`, color: `var(--color-text)` }}
          />
        </a>
      </div>


      <p className={styles.intro}>
        <b>Email:</b>{" "}contact[at]nemumi.net
      </p>
    </div>
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
